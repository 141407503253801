<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane
        key="1"
        :tab="
          lang == 'uz'
            ? 'Tekshirilmagan bemorlar ro\'yxati'
            : 'Список пациентов не прошедших обследование'
        "
      >
        <a-row :gutter="24" type="flex">
          <a-col :span="24" class="mb-24">
            <CardLaboratoryTable
              :data="table1Data"
              :columns="lang == 'uz' ? table1Columns : table1ColumnsRu"
            ></CardLaboratoryTable>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane
        key="2"
        :tab="
          lang == 'uz'
            ? 'Laboratoriyada tekshirilgan bemorlar ro\'yxati'
            : 'Список пациентов, обследованных в лаборатории'
        "
        force-render
      >
        <CardLaboratoryCheckedTable
          :data="table2Data"
          :columns="lang == 'uz' ? table2Columns : table2ColumnsRu"
        ></CardLaboratoryCheckedTable>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import axios from "../axios.js";
// "Authors" table component.
import CardLaboratoryTable from "../components/Cards/CardLaboratoryTable.vue";

// "Projects" table component.
import CardLaboratoryCheckedTable from "../components/Cards/CardLaboratoryCheckedTable.vue";

// "Authors" table list of columns and their properties.
const table1Columns = [
  {
    title: "RASMI",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ISMI",
    dataIndex: "patient.firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "patient.lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "patient.middleName",
  },
  {
    title: "JINSI",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "MANZILI",
    dataIndex: "patient.address",
    class: "text-muted",
  },
  {
    title: "YARATILGAN SANA",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table1ColumnsRu = [
  {
    title: "ФОТО",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ИМЯ",
    dataIndex: "patient.firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "patient.lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "patient.middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "АДРЕС",
    dataIndex: "patient.address",
    class: "text-muted",
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table2Columns = [
  {
    title: "RASMI",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ISMI",
    dataIndex: "patient.firstName",
  },
  {
    title: "FAMILIYASI",
    dataIndex: "patient.lastName",
  },
  {
    title: "SHARIFI",
    dataIndex: "patient.middleName",
  },
  {
    title: "JINSI",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "TUG'ILGAN SANASI",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "YARATILGAN SANA",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
  },
  // {
  //   title: "TAHLIL NATIJASI",
  //   dataIndex: "calculatedDisease",
  //   scopedSlots: { customRender: "calculatedDisease" },
  //   class: "text-muted",
  // },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

const table2ColumnsRu = [
  {
    title: "ФОТО",
    dataIndex: "avatar",
    scopedSlots: { customRender: "avatar" },
  },
  {
    title: "ИМЯ",
    dataIndex: "patient.firstName",
  },
  {
    title: "ФАМИЛИЯ",
    dataIndex: "patient.lastName",
  },
  {
    title: "ОТЧЕСТВО",
    dataIndex: "patient.middleName",
  },
  {
    title: "ПОЛ",
    dataIndex: "patient.gender",
    scopedSlots: { customRender: "gender" },
  },
  {
    title: "ДАТА РОЖДЕНИЯ",
    dataIndex: "patient.birthDate",
    scopedSlots: { customRender: "birthDate" },
    class: "text-muted",
    width: 160,
  },
  {
    title: "ДАТА СОЗДАНИЯ",
    dataIndex: "patient.createdAt",
    scopedSlots: { customRender: "createdAt" },
    class: "text-muted",
  },
  // {
  //   title: "TAHLIL NATIJASI",
  //   dataIndex: "calculatedDisease",
  //   scopedSlots: { customRender: "calculatedDisease" },
  //   class: "text-muted",
  // },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Authors" table list of rows and their properties.
const table1Data = [
  {
    key: "1",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Asadbek",
    last_name: "Alisherovich",
    surname: "Jumayev",
    sex: "ERKAK",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
  {
    key: "2",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Dilnoza",
    last_name: "Alisher qizi",
    surname: "Sattorova",
    sex: "AYOL",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
  {
    key: "3",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Asadbek",
    last_name: "Alisherovich",
    surname: "Jumayev",
    sex: "ERKAK",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
  {
    key: "4",
    avatar: {
      photo: "images/face-3.jpg",
    },
    first_name: "Asadbek",
    last_name: "Alisherovich",
    surname: "Jumayev",
    sex: "ERKAK",
    birth_date: "21.05.2001",
    address: "Toshkent shahar Yashnobod tumani Qamashi ko'chasi 25/1",
    status: 1,
  },
];
export default {
  components: {
    CardLaboratoryTable,
    CardLaboratoryCheckedTable,
  },
  data() {
    return {
      // Associating "Authors" table data with its corresponding property.
      table1Data: [],
      lang: null,
      // Associating "Authors" table columns with its corresponding property.
      table1Columns: table1Columns,
      table1ColumnsRu: table1ColumnsRu,

      // Associating "Projects" table data with its corresponding property.
      table2Data: [],

      table2Columns: table2Columns,
      table2ColumnsRu: table2ColumnsRu,
    };
  },
  mounted() {
    // axios.get("/api/patients").then((res) => {
    //   this.table1Data = res.data._embedded.patients;
    // });
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios
      .get("/api/analysis/search?size=700&sort=createdAt,desc")
      .then((res) => {
        let data = res.data.content;
        // let myArr = data.filter((item) => item.laboratoryId != null);
        // console.log(myArr);
        this.table1Data = data.filter((at) => !at.laboratoryId);
        // console.log(data);
        this.table2Data = data.filter((item) => item.laboratoryId != null);
      });
  },
  methods: {
    callback() {
      // console.log("CALLBACk");
    },
  },
};
</script>

<style lang="scss">
</style>